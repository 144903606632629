import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class PrefService {
    VIDEO_MODE_TAG = 'tag';
    VIDEO_MODE_FREQ = 'freq';
    VIDEO_MODE_MULTI_FREQ = 'multi-freq';
    VIDEO_MODE_NAME = 'prefVideoMode';
    VIDEO_LANGUAGE_NAME = 'prefVideoLanguage';
    VIDEO_LANGUAGE_FR = 'fr_FR';
    VIDEO_LANGUAGE_EN = 'en_US';

    constructor(
        private storage: Storage
    ) {
    }

    getVideoMode() {
        console.log('getVideoMode');
        /*
          this.storage.get(this.VIDEO_MODE_NAME).then(
              data => {
                  console.log('storage value : ');
                  console.log(data);
                  return data;
              }
          );*/
        return this.storage.get(this.VIDEO_MODE_NAME);
    }

    setVideoMode(videoMode) {
        console.log('setVideoMode : ');
        console.log(videoMode);
        this.storage.set(this.VIDEO_MODE_NAME, videoMode).then(
            data => {
                console.log(data);
            }
        );
    }

    getVideoLanguage() {
        console.log('getVideoLanguage');
        /*
          this.storage.get(this.VIDEO_MODE_NAME).then(
              data => {
                  console.log('storage value : ');
                  console.log(data);
                  return data;
              }
          );*/
        return this.storage.get(this.VIDEO_LANGUAGE_NAME);
    }

    setVideoLanguage(param) {
        console.log('setVideoLanguage : ');
        console.log(param);
        this.storage.set(this.VIDEO_LANGUAGE_NAME, param).then(
            data => {
                console.log(data);
            }
        );
    }
}
